import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { DetailsTaxVerse, linkResolver } from '@pwc-new-ventures/ui';
import Layout from '../layouts';

const postPrefix = 'insights';

export default function() {
  const { contentfulDetailsPage } = useStaticQuery(graphql`
    {
      contentfulDetailsPage(slug: { eq: "features" }) {
        metaTitle
        metaDescription
        image {
          ...ImageSharpMedium
        }
        heroCtaLink {
          slug
          internal {
            type
          }
        }
        heroCtaText
        heroCtaCopy
        heroDescription
        heroHeadline
        heroImage {
          ...ImageSharpLarge
        }
        navigatorsHeadline
        navigatorsBlurb {
          json
        }
        navigators {
          ... on ContentfulNavigator {
            headline
            blurb {
              json
            }
            icon {
              ...ImageSharpOriginal
            }
            ctaText
            ctaLink {
              ...NavigatorCtaLink
            }
            image {
              ...ImageSharpMedium
            }
          }
        }
        spacerNavigator {
          headline
          ctaText
          ctaLink {
            ...NavigatorCtaLink
          }
        }
        complianceHeadline
        complianceDescription {
          json
        }
        complianceFeatures {
          headline
          blurb {
            json
          }
          icon {
            ...ImageSharpOriginal
          }
        }
        complianceBackgroundImage {
          ...ImageSharpOriginal
        }
        complianceImage {
          ...ImageSharpMedium
        }
        clientsHeadline
        clientLogos {
          companyLogo {
            ...ImageSharpOriginal
          }
        }
        clientsDescription {
          json
        }
        clientsCtaText
        clientsCtaLink {
          slug
          internal {
            type
          }
        }
        storiesHeadline
        featuredStories {
          headline
          ctaText
          slug
          redirectUrl
          internal {
            type
          }
          image {
            ...ImageSharpStoryCard
          }
        }
      }
    }
  `);

  const doc = {
    meta: {
      title: contentfulDetailsPage.metaTitle,
      description: contentfulDetailsPage.metaDescription,
      image:
        contentfulDetailsPage.image &&
        contentfulDetailsPage.image.localFile.childImageSharp.fluid.src,
    },
    hero: {
      headline: contentfulDetailsPage.heroHeadline,
      body: contentfulDetailsPage.heroDescription,
      buttonHeadline: contentfulDetailsPage.heroCtaCopy,
      buttonText: contentfulDetailsPage.heroCtaText,
      buttonUrl:
        contentfulDetailsPage.heroCtaLink &&
        linkResolver({
          type: contentfulDetailsPage.heroCtaLink.internal.type,
          slug: contentfulDetailsPage.heroCtaLink.slug,
          postPrefix,
        }),
      image: contentfulDetailsPage.heroImage,
      textBackground: 'dark-2',
      ctaBackground: 'accent-1',
    },
    highlights: {
      header: {
        headline: contentfulDetailsPage.navigatorsHeadline,
        body: contentfulDetailsPage.navigatorsBlurb,
      },
      cards: contentfulDetailsPage.navigators.map((navigator) => ({
        icon: navigator.icon.localFile.publicURL,
        headline: navigator.headline,
        body: navigator.blurb,
        buttonText: navigator.ctaText,
        buttonType: navigator.ctaLink && navigator.ctaLink[0].internal.type,
        buttonUrl:
          navigator.ctaLink &&
          linkResolver({
            type: navigator.ctaLink[0].internal.type,
            slug: navigator.ctaLink[0].redirectUrl || navigator.ctaLink[0].slug,
            postPrefix,
          }),
        image: navigator.image,
        video: navigator.video,
      })),
    },
    compliance: {
      headline: contentfulDetailsPage.complianceHeadline,
      body: contentfulDetailsPage.complianceDescription,
      features: contentfulDetailsPage.complianceFeatures.map((feature) => ({
        headline: feature.headline,
        body: feature.blurb,
        image: feature.icon.localFile.publicURL,
      })),
      image: contentfulDetailsPage.complianceImage,
      backgroundImage:
        contentfulDetailsPage.complianceBackgroundImage &&
        contentfulDetailsPage.complianceBackgroundImage.localFile.publicURL,
    },
    logos: {
      headline: contentfulDetailsPage.clientsHeadline,
      body: contentfulDetailsPage.clientsDescription,
      images:
        contentfulDetailsPage.clientLogos &&
        contentfulDetailsPage.clientLogos.map(({ companyLogo }) => companyLogo.localFile.publicURL),
      buttonText: contentfulDetailsPage.clientsCtaText,
      buttonUrl:
        contentfulDetailsPage.clientsCtaLink &&
        linkResolver({
          type: contentfulDetailsPage.clientsCtaLink.internal.type,
          slug: contentfulDetailsPage.clientsCtaLink.slug,
          postPrefix,
        }),
      background: 'light-6',
    },
    cta: contentfulDetailsPage.spacerNavigator && {
      headline: contentfulDetailsPage.spacerNavigator.headline,
      buttonText: contentfulDetailsPage.spacerNavigator.ctaText,
      buttonType:
        contentfulDetailsPage.spacerNavigator.ctaLink &&
        contentfulDetailsPage.spacerNavigator.ctaLink[0].internal.type,
      buttonUrl:
        contentfulDetailsPage.spacerNavigator.ctaLink &&
        linkResolver({
          type: contentfulDetailsPage.spacerNavigator.ctaLink[0].internal.type,
          slug:
            contentfulDetailsPage.spacerNavigator.ctaLink[0].redirectUrl ||
            contentfulDetailsPage.spacerNavigator.ctaLink[0].slug,
          postPrefix,
        }),
      background: 'accent-1',
      noRule: true,
      primary: true,
    },
    stories: {
      headline: contentfulDetailsPage.storiesHeadline,
      cards: contentfulDetailsPage.featuredStories.map((card) => ({
        headline: card.headline,
        linkText: card.ctaText,
        linkUrl: linkResolver({
          type: card.internal.type,
          slug: card.redirectUrl || card.slug,
          postPrefix,
        }),
        image: card.image,
      })),
    },
  };

  return (
    <Layout title={doc.meta.title} description={doc.meta.description} image={doc.meta.image}>
      <DetailsTaxVerse doc={doc} Link={Link} />
    </Layout>
  );
}
